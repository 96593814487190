<template>
  <div v-if="currentUser.Role == 'Customer'" class="-content-block">

  <h2 class="app-title">
    <i class="fa-light fa-right-to-bracket"></i> 
    {{ title }}
  </h2>

  <div class="-content-block hide">
    <div class="dx-card _responsive-paddings has-paddings">

      <div class="-hide" style="font-size: 10px;">
        <p>id: {{ currentUser.id }}</p>
        <p>Rfid: {{ currentUser.Rfid }}</p>
        <p>Email: {{ currentUser.Email }}</p>
        <p>Avatar: {{ currentUser.Avatar }}</p>
        <p>Language: {{ currentUser.Language }}</p>
        <p>Role: {{ currentUser.Role }}</p>

        &nbsp;

        <p>FirstName: {{ customerFirstName }}</p>
        <p>LastName: {{ customerLastName }}</p>
      </div>

    </div>
  </div>

    <div class="grid-x">

      <DxPopup
        v-model:visible="qrScanUiVisible"
        :drag-enabled="false"
        :show-close-button="true"
        :show-title="true"
        :width="300"
        title="QR-Code scannen"
        position="center"
        @shown="qrScanOpen"
      >
        <div id="qrScanner">
        </div>
      </DxPopup>

      <div class="auto cell" style="width: 100%;">

        <div class="dx-card -has-paddings">
          <div class="" style="padding: 10px;">
            <div class="grid-x">
              <div class="shrink cell" style="margin-right: 10px;">

                <div class="user-thumb" v-if="currentUser.Avatar"
                  v-bind:style="{ 'background-image': 'url(' + currentUser.Avatar + ')' }" >
                </div>

              </div>
              <div class="auto cell">
                <p>{{ customerFirstName }} {{ customerLastName }}</p>
                <p style="font-size: 11px;">{{ customerRfid }}</p>
              </div>
            </div><!-- grid-x -->
          </div>
        </div><!-- dx-card -->

        <div class="dx-card" style="height: 220px;" v-if="isReverseQr === false">

          <div class="text-center" style="padding: 10px;">

            <QRCodeVue3 
              v-if="render"
              :width="200"
              :height="200"
              v-model:value="qrValue"
              :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
              :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
              :dotsOptions="{
                type: 'square',
                color: '#000000',
                gradient: {
                  type: 'linear',
                  rotation: 0,
                  colorStops: [
                    { offset: 0, color: '#000000' },
                    { offset: 1, color: '#000000' },
                  ],
                },
              }"
              :backgroundOptions="{ color: '#ffffff' }"
              :cornersSquareOptions="{ type: 'square', color: '#000000' }"
              :cornersDotOptions="{ type: undefined, color: '#000000' }"
              fileExt="png"
              :download="false"
              myclass="my-qur"
              imgclass="img-qr"
              downloadButton="qr-download-button cx-button tiny"
              :downloadOptions="{ name: 'vqr', extension: 'png' }"
            />

          </div>


        </div><!-- shrink -->

        <div class="dx-card dashboard-item" @click="qrScanUiVisible = true" v-else-if="isReverseQr === true">
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-camera"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">QR Scan</p>
          </div>
        </div>
      </div>

    </div>

    <div class="grid-x grid-margin-x small-up-2 -hide">

      <div class="cell" :class="{ hide: countAlertAdress < 1 }">
        <router-link to="/myprofile?adress">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertAdress"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-user"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Adresse</p>
          </div>
        </div>
        </router-link>
      </div>

      <div class="cell" :class="{ hide: countAlertFoto < 1 }">
        <router-link to="/myprofile?foto">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertFoto"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-camera"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Foto</p>
          </div>
        </div>
        </router-link>
      </div>

      <div class="cell" :class="{ hide: countAlertHealth < 1 }">
        <router-link to="/myprofile?health">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertHealth"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-heart-pulse"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Gesundheit</p>
          </div>
        </div>
        </router-link>
      </div>

      <div class="cell" :class="{ hide: countAlertMeasurement < 1 }">
        <router-link to="/myprofile?measurement">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertMeasurement"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-weight-scale"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Messungen</p>
          </div>
        </div>
        </router-link>
      </div>

      <div class="cell" :class="{ hide: countAlertExercise < 1 }">
        <router-link to="/trainingplan?exercise">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertExercise"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-dumbbell"></i>
          </div>

          <div class="text-center">
            <p class="dashboard-text">Trainingplan</p>
          </div>
        </div>
        </router-link>
      </div>

      <div class="cell" :class="{ hide: countAlertCourse < 1 }">
        <router-link to="/trainingplan?course">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertCourse"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-users"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Kurse</p>
          </div>
        </div>
        </router-link>
      </div>

      <div class="cell" :class="{ hide: countAlertDates < 1 }">
        <router-link to="/trainingplan?dates">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertDates"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-calendar-days"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Termine</p>
          </div>
        </div>
        </router-link>
      </div>

      <div class="cell" :class="{ hide: countAlertData < 1 }">
        <router-link to="/trainingplan?data">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertData"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-chart-simple"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Trainingsdaten</p>
          </div>
        </div>
        </router-link>
      </div>

      <div class="cell" :class="{ hide: countAlertAbo < 1 }">
        <router-link to="/contract?abo">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertAbo"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-ticket"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Abo's</p>
          </div>
        </div>
        </router-link>
      </div>
      
      <div class="cell" :class="{ hide: countAlertInvoice < 1 }">
        <router-link to="/contract?invoice">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertInvoice"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-file-invoice"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Rechnungen</p>
          </div>
        </div>
        </router-link>
      </div>
      
      <div class="cell" :class="{ hide: countAlertConfirmation < 1 }">
        <router-link to="/contract?confirmation">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertConfirmation"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-money-check-dollar"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Krankenkasse</p>
          </div>
        </div>
        </router-link>
      </div>
      
      <div class="cell" :class="{ hide: countAlertSignature < 1 }">
        <router-link to="/contract?signature">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertSignature"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-file-signature"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Unterschrift</p>
          </div>
        </div>
        </router-link>
      </div>

      <div class="cell" :class="{ hide: countAlertShopAbo < 1 }">
        <router-link to="/shop?abo">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertShopAbo"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-ticket"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Abo's</p>
          </div>
        </div>
        </router-link>
      </div>

      <div class="cell" :class="{ hide: countAlertShopProduct < 1 }">
        <router-link to="/shop?product">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertShopProduct"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-tag"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Produkte</p>
          </div>
        </div>
        </router-link>
      </div>

      <div class="cell" :class="{ hide: countAlertShopOffer < 1 }">
        <router-link to="/shop?offer">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertShopOffer"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-badge-percent"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Aktionen</p>
          </div>
        </div>
        </router-link>
      </div>

      <div class="cell" :class="{ hide: countAlertShopOrder < 1 }">
        <router-link to="/shop?order">
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertShopOrder"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-cart-shopping-fast"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Bestellungen</p>
          </div>
        </div>
        </router-link>
      </div>

    </div>


  </div><!-- content-block -->

  <div v-else class="-content-block">
    Oh no 😢
  </div><!-- content-block -->


</template>

<script>

import AppAlertDashboard from "../appdetail/app-alert-dashboard";

import auth from "../auth";
import { 
  apihost, 
} from "../api";

import QRCodeVue3 from "qrcode-vue3";
import * as OTPAuth from 'otpauth';
import { ref, nextTick } from 'vue';
import { Html5Qrcode } from "html5-qrcode";

import { DxPopup } from 'devextreme-vue/popup';
import notify from "devextreme/ui/notify";

const PERIOD = 10;

let currentUser;
let customerId;
let customerRfid;

let customerFirstName;
let customerLastName;
let customerEmail;
let customerEmail64;
let isReverseQr = ref(undefined);

let totp = new OTPAuth.TOTP({
  period: PERIOD,
  digits: 8,
  secret: customerEmail64,
});

let qrValue = ref(1111);
let render = ref(false);

let qrScanUiVisible = ref(false);

let countAlertAdress = 0;
let countAlertFoto = 0;
let countAlertHealth = 0;
let countAlertMeasurement = 0;
let countAlertExercise = 0;
let countAlertCourse = 0;
let countAlertDates = 0;
let countAlertData = 0;
let countAlertAbo = 0;
let countAlertInvoice = 0;
let countAlertConfirmation = 0;
let countAlertSignature = 0;
let countAlertShopAbo = 0;
let countAlertShopProduct = 0;
let countAlertShopOffer = 0;
let countAlertShopOrder = 0;

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

function updateQR(){
  const currentTime = Math.floor(Date.now() / 1000);
  const code = totp.generate();
  const codeObject = {
    id: customerEmail64,
    totp: code,
    t: currentTime 
  };
  
  qrValue.value = JSON.stringify(codeObject);
}

async function update() {
  const currentTime = Math.floor(Date.now() / 1000);
  const counter = PERIOD - (currentTime % PERIOD + 1);

  if(counter == 1) {
    updateQR();
    render.value = false;
    await nextTick();
    render.value = true;
  }
}

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;

  } // e.data
}); //auth

export default {

  name: "AppDashboard",


  components: {
    QRCodeVue3,

    AppAlertDashboard,
    DxPopup,

  },

  async mounted() {

    await this.fetchAlert();
    await this.fetchQrGlobal();
    await this.fetchCustomer();
    await update();
    setInterval(update, 1000);

  },

  data() {
    return {
      title: 'Check-in',

      apihost,
      currentUser,
      customerId,
      customerRfid,
      customerFirstName,
      customerLastName,

      qrValue,
      render,
      isReverseQr,

      qrScanUiVisible,

      countAlertAdress,
      countAlertFoto,
      countAlertHealth,
      countAlertMeasurement,
      countAlertExercise,
      countAlertCourse,
      countAlertDates,
      countAlertData,
      countAlertAbo,
      countAlertInvoice,
      countAlertConfirmation,
      countAlertSignature,
      countAlertShopAbo,
      countAlertShopProduct,
      countAlertShopOffer,
      countAlertShopOrder,

    };
  },
  methods: {

    async fetchCustomer() {

      fetch(apihost+'/'+currentUser.Language+'/vue/member/getmember/'+currentUser.id+'/view')
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result);
          //console.log(data);
          this.customerId = data.Id;
          this.customerRfid = data.Rfid;
          customerRfid = data.Rfid;
          this.customerFirstName = data.FirstName;
          this.customerLastName = data.LastName;
          this.customerEmail = data.Email;
          customerEmail = data.Email;
          customerEmail64 = btoa(customerEmail);
        })
        .catch(() => { throw 'Network error' });

    },

    async fetchAlert() {

      fetch(apihost+'/'+currentUser.Language+'/de/vue/member/memberalert/'+currentUser.id)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result);
          // console.log(data);

          this.countAlertAdress = data.countAlertAdress;
          this.countAlertFoto = data.countAlertFoto;
          this.countAlertHealth = data.countAlertHealth;
          this.countAlertMeasurement = data.countAlertMeasurement;
          this.countAlertExercise = data.countAlertExercise;
          this.countAlertCourse = data.countAlertCourse;
          this.countAlertDates = data.countAlertDates;
          this.countAlertData = data.countAlertData;
          this.countAlertAbo = data.countAlertAbo;
          this.countAlertInvoice = data.countAlertInvoice;
          this.countAlertConfirmation = data.countAlertConfirmation;
          this.countAlertSignature = data.countAlertSignature;
          this.countAlertShopAbo = data.countAlertShopAbo;
          this.countAlertShopProduct = data.countAlertShopProduct;
          this.countAlertShopOffer = data.countAlertShopOffer;
          this.countAlertShopOrder = data.countAlertShopOrder;

      })
      .catch(() => { throw 'Network error' });

    },

    async fetchQrGlobal() {
      fetch(`${apihost}/vue/global/getglobal/?reverseqr`)
        .then(response => response.json())
        .then(result => {
          isReverseQr.value = (result.reverseqr) ? true : false;
        })
        .catch(() => { throw 'Network error' });
    },

    qrScanOpen() {
      qrScanUiVisible.value = true;
      const qrScannerObj = new Html5Qrcode(
        "qrScanner"
      );
      const config = { fps: 10, qrbox: {width: 250, height: 250} };
      qrScannerObj.start({facingMode: "environment"}, config, async (t, r) => {
        console.log(`text: ${t}`, r);
        const splitText = t.split('#');
        await qrScannerObj.stop();
        qrScanUiVisible.value = false;

        const host = window.location.hostname.split('.')[0];
        const clientSocket = new WebSocket(`wss://node.mysportapp.ch/wss/${host}/ctl`);
        const commandObject = {
          cmd: "reverseqr",
          uid: encodeURIComponent(customerEmail64),
          serialnumber: splitText[1].split('-')[1],
          challenge: splitText[3],
        };
        console.log(commandObject);

        clientSocket.addEventListener("close", (e) => {
          if(e.code == 1000) {
            notify("Befehl erfolgreich gesendet.", "success", 3000);
          } else {
            notify("Befehl konnte nicht erfolgreich gesendet werden.", "error", 3000);
          }
        });
        clientSocket.addEventListener("open", () => {
          clientSocket.send(JSON.stringify(commandObject));
        });

      },
        () => {
          
        });
    },


  },
  unmounted() {

  },
};
</script>

<style>

</style>
